import React from "react";
import Logo from "./assets/logo/GreenLogo-01.png";

function Working() {
  return (
    <div className="flex align-center items-center justify-center content-center w-screen h-screen">
      <div className="text-center">
        <img src={Logo} className="w-48 md:w-64" alt="logo" />
        <h1>Coming soon.</h1>
      </div>
    </div>
  );
}

export default Working;
